const handleFileUploadInputDragEnter: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const target: any = event.currentTarget || event.target;

    target.classList.add('active');
  } catch (error) {
    console.error(error);
  }
};

const handleFileUploadInputDragLeave: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const target: any = event.currentTarget || event.target;

    target.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

const handleFileUploadInputDragOver: (event: any) => void = (event: any) => {
  event.preventDefault();
  event.stopPropagation();
};

const handleFileUploadInputDrop: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const target: any = event.currentTarget || event.target;
    const files = event.dataTransfer.files;
    const parent = target.closest('.fileUploaderInput');
    const hasFileTitle = parent.getElementsByClassName('hasFileTitle')[0];
    const hasFileSize = parent.getElementsByClassName('hasFileSize')[0];
    const input = parent.getElementsByTagName('input')[0];

    target.classList.add('loading');
    target.classList.remove('active');
    target.classList.remove('hasFile');
    target.classList.remove('uploadError');

    if (!files.length || !input) {
      target.classList.remove('uploadError');
      return;
    }

    input.files = event.dataTransfer.files;
    hasFileTitle.innerText = `${input.files[0].name} Uploaded`;
    hasFileSize.innerText = `${(input.files[0].size / 1024 / 1024).toFixed(2)} MB`;
    target.classList.remove('loading');
    target.classList.add('hasFile');
  } catch (error) {
    console.error(error);
    const target: any = event.currentTarget || event.target;
    target.classList.remove('loading');
    target.classList.remove('uploadError');
  }
};

const handleFileUploadInputChange: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const target: any = event.currentTarget || event.target;
    const parent = target.closest('.fileUploaderInput');
    const content = parent.getElementsByClassName('content')[0];
    const hasFileTitle = parent.getElementsByClassName('hasFileTitle')[0];
    const hasFileSize = parent.getElementsByClassName('hasFileSize')[0];
    const inputFiles = target.files;

    hasFileTitle.innerText = `${inputFiles[0].name} Uploaded`;
    hasFileSize.innerText = `${(inputFiles[0].size / 1024 / 1024).toFixed(2)} MB`;
    parent.classList.remove('invalid');
    content.classList.remove('loading');
    content.classList.remove('active');
    content.classList.add('hasFile');
    content.classList.remove('uploadError');
  } catch (error) {
    console.error(error);
    const target: any = event.currentTarget || event.target;
    target.classList.remove('loading');
    target.classList.remove('uploadError');
  }
};

const handleRemoveFileUploadInputFiles: (event: any) => void = (event: any) => {
  preventDefaultFunction(event);

  try {
    const target: any = event.currentTarget || event.target;
    const parent = target.closest('.fileUploaderInput');
    const input = parent.getElementsByTagName('input')[0];
    const content = parent.getElementsByClassName('content')[0];

    content.classList.remove('hasFile');
    content.classList.remove('uploadError');

    if (!input) return;

    input.value = '';
  } catch (error) {
    console.error(error);
  }
};

const getIsValidFileUploadInput: (input: any) => boolean = (input: any) => {
  try {
    return input.files && input.files.length > 0;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const setFileUploadInputError: (fileUploadInputID: string) => void = fileUploadInputID => {
  try {
    const fileUploadInput: any = document.getElementById(fileUploadInputID);
    const parent: any = fileUploadInput?.closest('.fileUploaderInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocateFileUploadInputValueByIdToNewInputByIdAndReturnSuccess: (
  fileUploadInputID: string,
  newInputID: string
) => boolean = (fileUploadInputID, newInputID) => {
  try {
    const fileUploadInput: any = document.getElementById(fileUploadInputID);
    const newDropdownInput: any = document.getElementById(newInputID);
    const fileUploadInputFiles: string = fileUploadInput?.files || '';

    if (!fileUploadInputFiles) return false;

    newDropdownInput.files = fileUploadInputFiles;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
